$('.slider').owlCarousel({
    loop:true,
    margin:0,
    nav:false,
    autoplay:false,
    smartSpeed: 1500,
    navText: ["<i class='fa fa-angle-left'></i>","<i class='fa fa-angle-right'></i>"],
    responsive:{
        0:{
            items:1
        },
        600:{
            items:1
        },
        1000:{
            items:1
        }

    }
});

// Move to top
$(document).ready(function () {

    $(window).scroll(function () {
        if ($(this).scrollTop() > 600) {
            $('.movetop').fadeIn();
        } else {
            $('.movetop').fadeOut();
        }
    });

    $('.movetop').click(function () {
        $("html, body").animate({
            scrollTop: 0
        }, 600);
        return false;
    });

});

$(document).on('click','.music_select',function(){
  var file = $(this).attr('music_path');
  musicPlay(file);
});

$(document).on('click','.music_select2',function(){
  var file = $(this).attr('music_path');
  
  musicPlay2(file);
});


// Music Player
//<![CDATA[
    $(document).ready(function(){
      
    /*$("#jquery_jplayer_1").jPlayer({
      ready: function (event) {
        console.log(event);
        $(this).jPlayer("setMedia", {
          //title: "Today is a Gift",
          m4a: "http://192.168.1.99/editube-service/assets/project/1/file-1550493695729.mp4",
          oga: "http://192.168.1.99/editube-service/assets/project/1/file-1550493695729.mp4"
        });
      },
      swfPath: "../../dist/jplayer",
      supplied: "m4a, oga",
      wmode: "window",
      useStateClassSkin: true,
      autoBlur: false,
      smoothPlayBar: true,
      keyEnabled: true,
      remainingDuration: true,
      toggleDuration: true
    });*/
    //file = "http://192.168.1.99/editube-service/assets/project/1/file-1550493695729.mp4"; 
    //musicPlay(file);
    });
    //]]>

function musicPlay(file){
  $("#jquery_jplayer_1").jPlayer("destroy");
  $("#jquery_jplayer_1").jPlayer({
      ready: function (event) {
        $(this).jPlayer("setMedia", {
          //title: "Today is a Gift",
          m4a: file,
          oga: file
        });
      },
      //swfPath: "../../dist/jplayer",
      supplied: "m4a, oga",
      wmode: "window",
      useStateClassSkin: true,
      autoBlur: false,
      smoothPlayBar: true,
      keyEnabled: true,
      remainingDuration: true,
      toggleDuration: true
    });
}

function musicPlay2(file){
  $("#jquery_jplayer_2").jPlayer("destroy");
  $("#jquery_jplayer_2").jPlayer({
      ready: function (event) {
        $(this).jPlayer("setMedia", {
          //title: "Today is a Gift",
          m4a: file,
          oga: file
        });
      },
      //swfPath: "../../dist/jplayer",
      supplied: "m4a, oga",
      wmode: "window",
      useStateClassSkin: true,
      autoBlur: false,
      smoothPlayBar: true,
      keyEnabled: true,
      remainingDuration: true,
      toggleDuration: true
    });
}

// Video
/*$(function() {
    $('#vidBox').VideoPopUp({
        backgroundColor: "#17212a",
        opener: "video1",
        idvideo: "v1",
        pausevideo: false
    });
});*/

$(document).on('click','.music_select',function(){
  _t = $(this);
  //music_select  
  $(".music_select").each(function() {
    $(this).removeClass('default-selection');
  });
  _t.addClass('default-selection');
});

// Dropdown Hover

$(function(){
  /*$(".main-menu .dropdown").hover(            
    function() {
        $('.dropdown-menu', this).stop( true, true ).fadeIn("fast");
        $(this).toggleClass('open');
    },
    function() {
        $('.dropdown-menu', this).stop( true, true ).fadeOut("fast");
        $(this).toggleClass('open');
    });*/
});

$(document).on('click','a.bla-1',function(e){
  //console.log('YYYYYYYYY');
  e.preventDefault();
  $("a.bla-1").YouTubePopUp({ autoplay: 1 });
});

// page loader
$(window).load(function() { 
    $(".body-load").fadeOut();
});

// Youtube Player
$(function(){
  
  //$("a.bla-2").YouTubePopUp( { autoplay: 0 } ); // Disable autoplay
});
// $(window).on('load',function(){
//     $('#music-popup').modal('show');
// });

$(document).on('click','.fresh_desk',function(){
  member_name = $(this).attr('member_name');
  member_email = $(this).attr('member_email');
  $.ajax({
    url: "https://www.editube.com/php/sso_login_freshdesk.php",
    type: "POST",
    data: {member_name : member_name,member_email:member_email},
    dataType: "json",
    success: function(data){
      if(typeof data[0]['redirect_url']!='undefined' && data[0]['redirect_url']!=''){
        window.open(data[0]['redirect_url'], '_blank'); 
      }
      //console.log(data[0]['redirect_url']);
    },
    error:function(data){
      //console.log('ERROR');
      //console.log(data);
    }
  });    
});

$(document).on('keypress','.onlyNumbers',function(e){
  
  if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
    //display error message
    //$("#errmsg").html("Digits Only").show().fadeOut("slow");
    return false;
  }
});

function validateNumber(event) {
  console.log(event);
    var key = window.event ? event.keyCode : event.which;
    if (event.keyCode === 8 || event.keyCode === 46) {
        return true;
    } else if ( key < 48 || key > 57 ) {
        return false;
    } else {
        return true;
    }
};

$(document).ready(function(){
    //$('.onlyNumbers').keypress(validateNumber);
});

$(function () {
  $('[data-toggle="tooltip"]').tooltip();
})

$(document).on('click','.eyes',function(){
  input_type = $(this).prev().attr('type');
  if(input_type=='password'){
    $(this).html('<i class="fa fa-eye"></i>');
    $(this).prev().attr('type','text');
  }
  else{
    $(this).html('<i class="fa fa-eye-slash"></i>');
    $(this).prev().attr('type','password');
  }
  
});

/* Login */
$(document).on('keypress','#login_password',function(e){
  if (e.which == 13) {
    $('form#login_form .loginMember').click();
    return false;    //<---- Add this line
  }
});

//forgot_email
$(document).on('keypress','#forgot_email',function(e){
  if (e.which == 13) {
    $('form#forgotpassword_form .forgotPassword').click();
    return false;    //<---- Add this line
  }
});

$(document).ready(function () {
    $(document).click(function (event) {
        var clickover = $(event.target);
        var _opened = $(".navbar-collapse").hasClass("show");
        if (_opened === true && !clickover.hasClass("navbar-toggler")) {
            $(".navbar-toggler").click();
        }
    });
});








  